import React from "react";
// import { useSiteMetadata } from '../hooks/useSiteMetadata'
import Helmet from "react-helmet";

import Navigation from "../components/Navigation/";
import usagicon from "../images/usagi.ico"

import style from "../styles/global.module.css";
import pixel from "../styles/_pixelart.module.scss";

export default () => (
    <div className={style.wrap}>
        <Helmet>
            <title>norweijian.</title>
            <link rel="icon" href={usagicon} />
        </Helmet>
        <Navigation />
        <main className={style.maincontainer}>
            <div id={pixel.table1}></div>
            <div id={pixel.barista}></div>
        </main>
    </div>
)
